0<!-- Control Finder -->

<template>
    <div class="ctrlfinder">         
      <v-text-field
          v-if="schema"
          v-bind="$input"
          v-model="get_value"
          :label="schema.label"
          :disabled="!edicion"
          :append-icon="icono"
          :clearable="edicion"
          readonly
          @click:clear="clear_ctrl()"
          @click:append="open_ctrl()">
      </v-text-field>       
    </div>
</template>



<script>

    export default {      
      props: {  
        finderName: { type:String, default: '' },
        fn: { type:Function, default: null }, 
        padre: { type:String, default: '' },
        schema: { type:Object, default: null },       
        edicion: { type: Boolean, default: true },
        componenteTipo: { type:String, default:'FC' },
        icono: { type: String, default: "mdi-magnify" },
      },

      
      computed: 
      {         
          // devuelvo el dato a mostrar en el textfield principal
          get_value: {           
            get() {             
              if (this.data!== undefined) { 
                  return typeof this.data['name_' + this.data_fieldName]=== 'undefined'? "añada campo name_" + this.data_fieldName : this.data[(this.data_fieldName)];
              }

              return this.schema.comp.valueaux;
            },

            set() {}
          }
      },


      methods: 
      {
        // borro los datos del
        clear_ctrl() {
          this.schema.value= "0";
          this.schema.comp.valueaux= "";
          this.$emit('clear');
        },

        //
        open_ctrl() {
          if (!this.finderName) return;
          this.$root.$common_finder.open(this.finderName, this.schema, this.componenteTipo, this.fn, this.padre);          
        }

      }

    };
</script>


